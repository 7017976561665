<template>
    
    
    <b-card   no-body class=" m-0 p-0 chat-app-container">
        
        <b-card-header class="border-bottom p-1 shadow">
            <div class="d-flex align-items-center">
                
                <b-link class="ml-1 mr-1" @click="$router.go(-1)">
                    <feather-icon icon="ArrowLeftIcon" size="22" />
                </b-link>
                
                <b-avatar
                    :variant="Users.utils.resolveAvatarColor( ticket.user.id )"
                    :text="Users.utils.capitalize(`${ticket.user.firstName}`, 1 )"
                />
                <div class="d-flex flex-column font-small-3" style="margin-left: 12px;">
                    <span class="text-capitalize m-0 text-black">
                        {{ ticket.user.firstName }} {{ ticket.user.lastName }}
                    </span>
                    <span class="text-muted font-small-3 text-muted">
                        {{ ticket.user.email }}
                    </span>
                </div>
            </div>
            <div>
                <span class="text-muted font-small-3">
                    {{ utils.lastActivityFormat(ticket.updated_at) }}
                </span>
            </div>
        </b-card-header>
        
        <b-card-body class="p-0">
            <div v-if="messages" class="chat-app">
                <div class="chat-body">
                    <div v-for="(message,index) in messages.items" :key="`message:${index}:${message.id}`" :class="`chat-message ` + isInOut(index) ">
                        <message-view :source="message" :index="index" :messages="messages.items" :ticket="ticket" />
                    </div>
                </div>
            </div>
        </b-card-body>
        
        <b-card-footer class="border-top p-1">
            <div class="d-flex justify-content-around align-items-end">
                <b-button v-ripple class="m-0 p-0" variant="transparent" style="min-width: 32px; height: 32px; border-radius: 50%;">
                    <feather-icon icon="PaperclipIcon" size="22" />
                </b-button>
                <div class="flex-grow-1">
                    <div></div>
                    <div class="message-editor">
                        <quill-editor
                            ref="editor"
                            id="quil-content"
                            v-model="message.text"
                            :options="editorOption"
                          />
                    </div>
                </div>
                <b-button @click="sendMessage" :disabled="message.text.trim().length === 0" v-ripple class="m-0 p-0" variant="primary" style="min-width: 32px; height: 32px; border-radius: 50%;">
                    <feather-icon icon="ArrowUpIcon" size="22" />
                </b-button>
            </div>
        </b-card-footer>
        
    </b-card>
    
    
<!--    <div class='d-flex flex-column justify-content-between pr-1' style='height: calc(100vh - 66px)'>
        <b-card no-body style='border-radius: 0 !important;' class='border-bottom'>
            <b-card-header class='p-1'>
                <div class="d-flex align-items-center">
                    <b-avatar
                        :variant="Users.utils.resolveAvatarColor( ticket.user.id )"
                        :text="Users.utils.capitalize(`${ticket.user.firstName}`, 1 )"
                        style='width: 45px; height: 45px; font-size: 20px;'
                    />
                    <div class="d-flex flex-column font-small-3" style="margin-left: 12px;">
                        <span class="text-capitalize m-0 text-black" style='font-size: 16px;'>
                            {{ ticket.user.firstName }} {{ ticket.user.lastName }}
                        </span>
                        <span class="text-muted font-small-3 text-muted">
                            {{ ticket.user.email }}
                        </span>
                    </div>
                </div>
                
                <div>
                    <span class="text-muted font-small-3">
                        {{ utils.lastActivityFormat(ticket.updated_at) }}
                    </span>
                </div>
                
            </b-card-header>
        </b-card>
        
        <b-card bg-variant='transparent' class='flex-grow-1'>
            <div class='d-flex flex-column-reverse h-100'>
                <b-card bg-variant='primary'></b-card>
            </div>
        </b-card>
        
        <b-card no-body class='m-0 p-0' style='border-radius: 0 !important;'>
            <b-card-footer class='border-top'></b-card-footer>
        </b-card>
    </div>-->
</template>

<script>
    
    import Quill from 'quill'
    var Delta = Quill.import('delta');


    import Users from "@/modules/admin/users/"
    import utils from "@/modules/utils/"
    import VirtualList from 'vue-virtual-scroll-list'
    import MessageView from "./widgets/Message"
    import { quillEditor } from 'vue-quill-editor'
    
    
    export default {

        data() {
            return {
                Users,
                utils,
                messages: null,
                overflow: true,
                
                editorOption: {
                    theme: 'bubble',
                    placeholder: "Ваше сообщение",
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            ['link']
                        ],
                        clipboard: {
                                matchers: [
                                        [ Node.ELEMENT_NODE, function(node, delta) {
                                                return delta.compose(new Delta().retain(delta.length(), 
                                                { color: false,
                                                  background: false,
                                                  bold: false,
                                                  strike: false,
                                                  underline: false
                                                }
                                                ));
                                          }
                                        ] 
                                ]
                        },
                        keyboard: {
                            bindings: {
                                custom: {
                                    key: 'enter',
                                    shiftKey: true,
                                    handler: (range, context) => {
                                         this.sendMessage();
                                    }
                                },
                            }
                        }
                    }
                },
                
                message: {
                    id: -1,
                    user: {
                        id: this.$user.self.id,
                        firstName: this.$user.self.firstName,
                        lastName: this.$user.self.lastName,
                    },
                    text: "",
                    timestamp: -1,
                    read: false,
                }
                
            }
        },
        props: {
            ticket: Object
        },
        methods: {
            
            sendMessage( args ) {
                
                if( this.message.text.trim().length === 0 ) return;
                
                let message = {};
                
                Object.keys( this.message ).forEach( key => message[key] = this.message[key] );
                message['timestamp'] = Math.floor(Date.now() / 1000);
                                                
                this.$request.get("tickets/send", {
                    ticket: this.ticket.id,
                    text: message.text,
                    reply: message.reply ? message.reply.id : null
                }).then( rsp => {
                    message.id = rsp.id;
                });
                
                this.messages.items.unshift( message );
                
                this.message.text = "";
                this.message.reply = null;
                
            },
            
            isInOut( index ) {
                
                let message = this.messages.items[ index ];
                
                if( message.user.id === this.$user.self.id ) {
                    return 'is-out';
                }
                
                return 'is-in';
                                
                if( message.user.id === this.ticket.user.id ) {
                    return 'is-in';
                } else {
                    return 'is-out';
                }
                
                
            },
        },
        
        computed: {
            
            editor() {
                return this.$refs.editor.quill;
            }
            
        },
        
        components: {
            MessageView,
            VirtualList,
            quillEditor
        },
        watch: {

        },
        mounted() {
                        
            this.$request.get("tickets/messages", {
                id: this.ticket.id
            }).then( messages => {
                this.messages = messages;       
            });
        }

    }

</script>

<style lang="scss">

    @import '@core/scss/vue/libs/quill.scss';   
    
    .quill-editor {
        .ql-container.ql-snow {
          border: none !important;
        }
   }
   
   /*.message-editor {*/
       [dir] .ql-editor {
           padding-top: 6px !important;
           padding-bottom: 6px !important;
           max-height: 155px;
       }
   /*}*/
    
</style>