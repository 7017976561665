<template>
    <div>
        <div v-if="isFirst" class="chat-message-header">
            {{ message.user.firstName }} {{ message.user.lastName }}
        </div>
        <!--<div class="chat-message">-->
            <div :class="{
                    'chat-message-bubble' : true,
                    'is-first' : !isLast && isFirst,
                    'is-middle' : !isLast && !isFirst,
                    'is-last': isLast && !isFirst,
                }">
                <div v-if="message.reply" class="chat-message-reply">
                    <p class="reply-author">
                        {{ message.reply.user.firstName }} {{ message.reply.user.lastName }}
                    </p>
                    <p class="reply-text" v-html="message.reply.text.replace(/<(.|\n)*?>/g, '')"></p>
                </div>
                <div class="chat-message-text" v-html="message.text"></div>
                <div class="chat-message-time">{{ utils.lastActivityFormat( message.timestamp) }}</div>
            </div>
        <!--</div>-->
        <div v-if="isOut && isLast" class="chat-message-footer">
            <template v-if="message.id < 0">Отправка...</template>
            <template v-else-if="message.read">Прочитано</template>
            <template v-else>Доставлено</template>
        </div>
    </div>
</template>

<script>
    
    import utils from "@/modules/utils/"

    export default {

        data() {
            return {
                utils
            }
        },
        props: {
            source: Object,
            index: Number,
            ticket: Object,
            messages: Array
        },
        methods: {
        },
        components: {

        },
        computed: {
            
            isFirst() {
                
                let prevMessage = this.messages[this.index + 1];
                if( prevMessage ) {
                    if( prevMessage.user.id === this.message.user.id ) {
                        return false;
                    }
                }
                
                return true;
                
            },
            
            isLast() {
                
                let prevMessage = this.messages[this.index - 1];
                if( prevMessage ) {
                    if( prevMessage.user.id === this.message.user.id ) {
                        return false;
                    }
                }
                
                return true;
                
            },
            
            isOut() {                
//                return true;
               return this.message.user.id === this.$user.self.id;
//                this.ticket.user.id !== this.message.user.id;
//                return this.ticket.user.id !== this.message.user.id;
            },
            message() {
                return this.source;
            }
        },
        watch: {

        },
        mounted() {

        }

    }

</script>